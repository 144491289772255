<template>
  <div class="bg-white p-1">
    <div class="tab-header-content-extra-fields row">
      <div class="col-12 col-md-8"><p class="m-0">Campos adicionales son campos y valores que los Sellers envían por API como "extra_fields". Indicar en nombre
        campo el nombre que utiliza el seller al enviar la información para luego indicar en donde se deben mostrar
        estos.</p></div>
      <div>
        <b-button variant="warning" @click="openModal('modalNewExtraField')">Nuevo campo adicional</b-button>
      </div>      
    </div>

    <table-render :schema="schema" :rows="rows" :actions="actions" class="px-2" v-show="!loading.first">
      <template #details_delivery="scope">
        <feather-icon :icon="scope.rowdata.in && scope.rowdata.in['delivery-details'] ? 'CheckIcon' : 'XIcon'" size="16"
          :class="scope.rowdata.in && scope.rowdata.in['delivery-details'] ? 'text-success' : 'text-danger'" />
      </template>
      <template #download_delivery="scope">
        <feather-icon :icon="scope.rowdata.in && scope.rowdata.in['deliveries-export'] ? 'CheckIcon' : 'XIcon'" size="16"
          :class="scope.rowdata.in && scope.rowdata.in['deliveries-export'] ? 'text-success' : 'text-danger'" />
      </template>
    </table-render>
    <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table :rows="10" :columns="schema.length || 6" :table-props="{}" />
    </div>

    <ModalNewExtraField ref="modalNewExtraField" @createNewExtraField="addExtraFields" :fieldData="fieldData"
      :buttonDisabled="toggleButton">
    </ModalNewExtraField>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import ModalNewExtraField from './ModalNewExtraField.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'tabExtraFields',
  components: {
    ModalNewExtraField
  },
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      fieldData: {},
      toggleButton: false,
      loading: {
        first: true
      }
    }
  },
  methods: {
    setInitialData() {
      const params = { company_id: this.$route.params.id }
      this.schema = [
        { label: 'Nombre llave', key: 'key' },
        { label: 'Etiqueta', key: 'label' },
        { label: 'Detalle de envío', key: 'details_delivery', useSlot: true },
        { label: 'Descarga de envíos', key: 'download_delivery', useSlot: true },
        { label: 'Acciones', key: 'actions', class: ['text-center'] }
      ]
      this.actions = [
        { action: id => this.updateExtraFields(id), icon: 'Edit2Icon', color: 'success', text: 'Editar campo' },
        { action: id => this.deleteExtraFields(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
      this.getExtraFields(params)
    },
    getExtraFields(params) {
      this.$store.dispatch('fetchService', { name: 'getCompaniesExtraFields', params })
    },
    addExtraFields(form, add = false, remove = false) {
      this.toggleButton = true
      const params = { company_id: this.$route.params.id }
      let newsExtrafield = this.companiesExtraFields
      if (remove) {
        newsExtrafield = this.rows.filter(field => field.id !== form)
      } else {
        const extraField = {
          key: form.nameKey,
          label: form.label,
          in: {
            ['delivery-details']: form.deliveryDetails,
            ['deliveries-export']: form.deliveryDownload
          }
        }
        if (!add) {
          newsExtrafield.push(extraField)
        } else {
          newsExtrafield[form.id] = extraField
        }

      }
      const queryParams = newsExtrafield
      this.$store.dispatch('fetchService', {
        name: 'updateCompaniesExtraFields',
        params,
        queryParams,
        onSuccess: () => {
          this.closeModal('modalNewExtraField')
          this.getExtraFields(params)
          this.$success(this.$t(remove ? 'msg-exito-eliminar-elemento' : 'msg-exito-actualizar'))
          this.toggleButton = false
        },
        onError: () => {
          this.toggleButton = false
        }
      })
    },
    updateExtraFields(id) {
      this.fieldData = this.rows[id]
      this.openModal('modalNewExtraField')
    },
    deleteExtraFields(id) {
      this.addExtraFields(id, false, true)
    },
    openModal(nameId) {
      this.$bvModal.show(nameId)
    },
    closeModal(nameModal) {
      this.$refs.modalNewExtraField.cleanForm()
      this.$bvModal.hide(nameModal)
      this.fieldData = {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      companiesExtraFields: 'getCompaniesExtraFields'
    })
  },
  watch: {
    companiesExtraFields() {
      this.rows = this.companiesExtraFields
      this.rows = this.companiesExtraFields.map((extraField, index) => {
        return {
          ...extraField,
          id: index
        }
      })
      this.loading.first = false
    }
  }
}

</script>
<style>
.tab-header-content-extra-fields p {
  margin: 1rem;
}

.tab-header-content-extra-fields button {
  margin: 1rem;
}
</style>