<template>
  <b-modal id="modalNewExtraField" :title="$t('Campo adicional')" no-close-on-backdrop centered modal-class="dialog-800"
    @close="close" hide-footer>
    <form-render :fields="fields" containerButtonsClass="col-sm-12" :buttonSend="buttonSend" :form.sync="form"
      ref="formNewExtraField" :key="keyFormNewExtraField" @send="createNewExtraField">
      <template #showIn>
        <h5>{{ $t('Campo adicional') }}</h5>
      </template>


    </form-render>
  </b-modal>
</template>
<script>
import FormRender from '@/views/components/custom/form-render/FormRender.vue'
export default {
  comments: { FormRender },
  name: 'modalNewExtraField',
  props: ['onClose', 'fieldData', 'buttonDisabled'],
  data() {
    return {
      fields: [],
      buttonSend: { text: 'Crear campo', icon: '', color: 'warning'},
      keyFormNewExtraField: 0,
      form: {}
    }
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'nameKey', label: this.$t('Nombre llave'), containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'nombre Llave', validation: 'required', specialVerification: this.normalizeNameKey},
        { fieldType: 'FieldInput', name: 'label', label: this.$t('Etiqueta'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { label:this.$t('Mostrar en'), useSlot: true, key: 'ShowIn'},
        { fieldType: 'FieldCheckbox', name: 'deliveryDetails', containerClass: 'col-sm-12 col-md-12 container-info justify-content-center', options: [{ id: true, text: 'Detalle de envío' }] },
        { fieldType: 'FieldCheckbox', name: 'deliveryDownload', containerClass: 'col-sm-12 col-md-12 container-info justify-content-center', options: [{ id: true, text: 'Descarga de envío' }] }
      ]
    },
    close() {
      this.cleanForm()
      this.$emit('onClose', 'modalNewExtraField')
    },
    createNewExtraField() {
      this.$emit('createNewExtraField', this.form, Object.keys(this.fieldData).length > 0)
    },
    normalizeNameKey(value) {
      return value.replace(/\s+/g, '_')
    },
    cleanForm() {
      this.form = {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    fieldData() { 
      this.form = {
        nameKey: this.fieldData.key,
        label: this.fieldData.label,
        deliveryDetails: this.fieldData.in?.['delivery-details'] || false,
        deliveryDownload: this.fieldData.in?.['deliveries-export'] || false,
        id: this.fieldData.id
      }
      this.buttonSend.text = 'Editar campo'
    },
    buttonDisabled() {
      this.buttonSend.disabled = this.buttonDisabled
    }
  }
}
</script>
<style></style>